<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
    <div class="second-tab-div">
      <taobao-item ref="taobaoItem" :init-data="false" @page-loading="setClientSelectLoading"></taobao-item>
    </div>
  </znl-layout-spa>
</template>
<script>
import taobaoItem from "@c_modules/Taobao/Item";
// 窗口大小监控mixin
import Resize from '~assets/scripts/mixins/resize';
import { isClient } from "~/lib/runtime";

export default {
  name: "VTencentQDW",
  components: {
    taobaoItem
  },
  mixins: [Resize],
  data() {
    return {
      pageData: [],
      pageLoading: false,
      activeName: "ProductList"
    };
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    }
  },
  methods: {
    onresize(){
      //从store取得domRect，在计算
      const height = isClient()
        ? this.$store.state.domRect.clientHeight
        : this.$store.state.domRect.clientHeight - 50;

      if(this.$refs.taobaoItem){
        this.$refs.taobaoItem.$refs.table.boxHeight = height - 6
      }
    },
    pageSearch() {},
    setClientSelectLoading(val) {
      this.pageLoading = val;
    }
  },
  async created() {}
};
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_variables.scss";
</style>
