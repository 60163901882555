<template>
  <!-- 淘宝订单明细 -->
  <div class="module-wrapper m-stkout-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-stkout-order-line"
      :columns="columns"
      :item-source="itemSource"
      checkboxBindingKey="NumIidStr"
      :show-title-menus="true"
      @on-item-models-click="onItemModelsClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            form-type="select"
            :select-options="linkOptions"
            placeholder="关联状态"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="80px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.LinkStatus"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="商家编码"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.OuterId"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="宝贝ID"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.NumIidStr"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="宝贝标题"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="180px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.Title"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="已关联的ERP型号"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="120px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.ItemModels"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="storeList"
            placeholder="店铺名称"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="100px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.TaobaoUserId"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            :select-options="itemStatus"
            placeholder="宝贝状态"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="90px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="searchFields.ApproveStatus"
            type="text"
          ></znl-input>

          <!-- <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="订单日期从"
            size="mini"
            value-format="yyyy-MM-dd"
            layout="left"
            width="100px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="searchFields.StartCreated"
          ></znl-input>
          <span class="mr5">到</span>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="订单日期止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="100px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="searchFields.EndCreated"
          ></znl-input> -->

          <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
          </znl-button>
        </el-row>
      </div>
    </znl-table>

    <item-sotck-link
      v-if="showItemLink"
      :visible="showItemLink"
      :taobao-item="currentRow"
      @close="() => {showItemLink = false}"
      @confirm="onStockLinkConfirm"
    ></item-sotck-link>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import ItemSotckLink from "@c_modules/Taobao/ItemStockLink";

import { on } from "~assets/scripts/utils/dom";

const CONFIG = {
  PK: "NumIidStr",
  configURL: "TaobaoOrderLine/GetConfig",
  searchURL: "TaobaoStock/GetPageListStock",
  setLinkedStock: "TaobaoOrder/SetLinkedStock",
  updateInquireQty: "TaobaoOrder/UpdateInquireQty",
  getStoreNameList: "TaobaoShop/GetSetting",
  delLinkedStock: "TaobaoOrder/DelLinkedStock"
};

const gridCols = [
  {
    CTitle: "宝贝ID",
    BindField: "NumIidStr",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DataType: 1
  },
  {
    CTitle: "宝贝图片",
    BindField: "PicUrl",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 1
  },
  {
    CTitle: "宝贝标题",
    BindField: "Title",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 220,
    DataType: 1
  },
  {
    CTitle: "商家编码",
    BindField: "OuterId",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 100,
    DataType: 1
  },
  {
    CTitle: "关联的ERP库存",
    BindField: "ItemModels",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 200,
    DataType: 1
  },
  {
    CTitle: "店铺名称",
    BindField: "ShopName",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DataType: 1
  },
  {
    CTitle: "价格",
    BindField: "Price",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "ERP库存采购价",
    BindField: "BuyPrices",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 150,
    DataType: 2
  },
  {
    CTitle: "库存",
    BindField: "Num",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "销量",
    BindField: "SoldQuantity",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "状态",
    BindField: "ApproveStatus",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DataType: 2
  },
  {
    CTitle: "上架时间",
    BindField: "ListTime",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DataType: 4
  },
  {
    CTitle: "下架时间",
    BindField: "DelistTime",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DataType: 1
  },
  {
    CTitle: "最后同步时间",
    BindField: "LastSyncTime",
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DataType: 1
  }
];

export default {
  name: "TaobaoItemList",
  config: CONFIG,
  mixins: [getCommonDataMixin],
  components: {
    ItemSotckLink
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      pageIndex: 1,
      pageSize: 30,
      totalCount: 0,
      linkOptions: [
        { key: 'link', value: '已关联' },
        { key: 'unlink', value: '未关联' }
      ],
      itemStatus: [
        { key: 'onsale', value: '出售中'},
        { key: 'instock', value: '仓库中'},
      ],
      storeList: [],

      searchFields: {
        LinkStatus: '',
        OuterId: '',
        NumIidStr: '',
        Title: '',
        TaobaoUserId: null,
        ApproveStatus: null,
        ItemModels: ''
      },

      showItemLink: false,
      currentRow: null
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
    // 挂载一个window 事件，接收CS客户端派遣的event。接收参数为 PrimaryKey, data(supplierInquire)
    on(window, "onCSInqureSaved", e => {
      let { PrimaryKey, data } = e;
      this.setInquired(PrimaryKey, data);
    });
  },
  methods: {
    onInit: async function(config) {
      // if (!config) {
      //   config = await this.isSaveStorage("TaobaoItem", CONFIG.configURL);
      // }
      let columns = this.onInitCols(gridCols);
      this.columns = columns;
      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
      this.itemSource = [];

      this.initStoreList();
      this.showItemLink = false
    },

    onInitCols(columns) {
      // 图片
      let colIndex = _.findIndex(columns, col => col.BindField === "PicUrl");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          title: "图片",
          width: 50,
          type: "panel",
          event: "",
          trigger(rowData, rowIndex, field) {
            return `<img src="${rowData.PicUrl}" style="height: auto; width:100%;" />`;
          },
          render(rowData, rowIndex, field) {
            return `<img src="${rowData.PicUrl}" />`;
          }
        });

        // 关联型号
        colIndex = _.findIndex(columns, item => item.BindField === "ItemModels");
        if (colIndex > -1) {
          _.extend(columns[colIndex], {
            // event: "on-item-models-click",
            // className: "v-cell-link",
            width: 200,
            type: "button",
            isDeal: false,
            canSearch: false,
            title: "关联型号",
            className: "init-data",
            btnRender: (row, col) => {
              let className = "init-data-opera";
              let html = ''
              if (_.isEmpty(row.ItemModels)) {
                html = '[设置关联]'
              } else {
                html = row.ItemModels
              }
              html = '<div title="点击设置库存关联">' + html + '</div>'
              return html
            },
            showButton(row) {
              return true;
            },
            click: (row, index) => {
              this.onItemModelsClick(row);
              return true;
            }
          });
        }

        //商品状态
        let statusArry = [
          { value: 'onsale', key: '出售中'},
          { value: 'instock', key: '仓库中'},
        ]
        colIndex = _.findIndex(columns, item => item.BindField === 'ApproveStatus')
        if (colIndex > -1) {
          _.extend(columns[colIndex], {
            type: 'select',
            width: 80,
            options: statusArry
          })
        }
      }
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      if (!viewBuyPrice) {
        _.remove(columns, col => col.BindField === "BuyPrices")
      }
      return columns;
    },

    async onBindData (pageIndex = 1) {
      let param = _.extend(
        {},
        this.searchFields,
        {
          PageIndex: pageIndex,
          PageSize: this.pageSize
        }
      );
      if (this.searchFields.LinkStatus === 'link') {
        param.IsLink = true
      } else if (this.searchFields.LinkStatus === 'unlink') {
        param.IsLink = false
      }
      return await this.$post(CONFIG.searchURL, param, (data, logic) => {
        console.log(data, logic);

        if (logic.code === 200) {
          this.itemSource = data.ResultList
          this.pageIndex = data.PageIndex
          this.totalCount = data.TotalCount
        } else {
          let msg = logic.msg || "查询发生异常"
          this.$message({message: msg, type: 'error'})
        }
      });
    },

    async onSearch () {
      this.onBindData(1)
    },
    async onHeadSearch() {
      this.onBindData();
    },

    // 初始化列表
    initStoreList() {
      this.$post(CONFIG.getStoreNameList, {}, (data, logic) => {
        if (logic.code === 200) {
          _.each(data, item => {
            if (this.hasValue(item.ShopName)) {
              this.storeList.push({
                key: item.TaobaoUserId,
                value: item.ShopName
              });
            }
          });
        }
      });
    },

    // 关联ERP型号单击
    onItemModelsClick (row) {
      this.currentRow = row
      if (_.isEmpty(row.OuterId)) {
        this.$message({message: '商家编码为空，请先在淘宝上面设置商家编码', type: 'error'})
      } else {
        this.showItemLink = true
      }
    },

    async onStockLinkConfirm(refresh) {
      if (refresh) {
        this.onBindData(this.pageIndex)
      }
      this.showItemLink = false
    }
  }
};
</script>

<style lang="scss">
</style>
